<template>
  <div class="js-common-wrap js-data-management">
    <div class="js-common-head">
      <div>
        <el-button
          class="back-btn"
          @click="$goBack"
          icon="el-icon-arrow-left"
          size="small"
          type="primary"
          plain
        ></el-button>
        <el-radio-group
          class="label"
          v-model="type"
          size="small"
          @change="getList"
        >
          <el-radio-button :label="1">人脸</el-radio-button>
          <el-radio-button :label="0">车牌</el-radio-button>
          <el-radio-button :label="2">二维码</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button type="primary" size="small" @click="search"
          >高级搜索</el-button
        >
        <el-button type="primary" size="small" @click="handleDownload">
          下载 <i class="el-icon-download"></i>
        </el-button>
      </div>
    </div>
    <div class="js-common-content">
      <div ref="imageWrapper" v-if="linChartArr.length">
        <div
          class="echarts-box"
          v-for="(item, index) in linChartArr"
          :key="index"
        >
          <h2 class="chart-title">{{ item.userName }}</h2>
          <echarts-component :options="item.lineChart"></echarts-component>
        </div>
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
    </div>
    <common-pagination
      v-model="pageOptions"
      @curPageChange="handleCurPageChange"
    ></common-pagination>
    <data-manage-search
      v-model="searchDialogVisible"
      @handleSearch="handleSearch"
    ></data-manage-search>
  </div>
</template>
<script>
import EchartsComponent from "@/components/EchartsComponent";
import CommonPagination from "@/components/CommonPagination";
import DataManageSearch from "@/components/DataManageSearch";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
  name: "",
  components: {
    EchartsComponent,
    CommonPagination,
    DataManageSearch,
  },
  data() {
    return {
      type: 1,
      searchDialogVisible: false,
      linChartArr: [],
      options: {
        startDate: "",
        endDate: "",
      },
      pageOptions: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      htmlTitle: "页面PDF文件",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let url = "";
      if (this.type === 1) {
        url = "api/csp/afr/v1/afrStat/page";
      } else if (this.type === 0) {
        url = "api/csp/lpr/v1/lprStat/page";
      } else if (this.type === 2) {
        url = "api/csp/qr/v1/qrStat/page";
      }
      this.linChartArr = [];
      this.$axios({
        method: "get",
        url: url,
        params: {
          startDate: this.options.startDate,
          endDate: this.options.endDate,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage,
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.pageOptions.total = data.data.total;
          let list = data.data.rows;
          list.forEach((item) => {
            this.linChartArr.push({
              userName: item.userName,
              lineChart: this.formatData(item.captureList),
            });
          });
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 格式化返回的数据
    formatData(list) {
      let x = [],
        y = [];
      for (let item in list) {
        x.push(list[item].captureDate.slice(0, 10));
        y.push(list[item].captureCount);
      }
      let lineChart = {
        title: {
          left: "center",
          bottom: 10,
          text: "",
          textStyle: {
            fontSize: 14,
          },
        },
        xAxis: {
          type: "category",
          axisLine: {
            show: true,
            onZero: true,
          },
          axisTick: {
            show: true,
            inside: false,
          },
          boundaryGap: true,
          alignWithLabel: true,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        grid: {
          left: 50,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
          },
        ],
      };
      return this.setLineChart(lineChart, x, y);
    },
    setLineChart(lineChart, x, y) {
      switch(this.type){
        case 0:
          lineChart.title.text = '识别人像总数'
        break
        case 1:
          lineChart.title.text = '识别车牌总数'
        break
        case 2:
          lineChart.title.text = '识别二维码总数'
        break
      }
      lineChart.xAxis.data = x;
      lineChart.series[0].data = y;
      return lineChart;
    },
    // 分页
    handleCurPageChange() {
      this.getList();
    },
    search() {
      this.searchDialogVisible = true;
    },
    handleSearch(params) {
      this.pageOptions.page = 1;
      this.searchDialogVisible = false;
      this.options.startDate = params.startDate;
      this.options.endDate = params.endDate;
      this.getList();
    },
    getPdf() {
      let title = this.htmlTitle;
      html2canvas(this.$refs.imageWrapper, {
        allowTaint: true,
      }).then(function (canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new jsPDF("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
      });
    },
    handleDownload() {
      if (this.linChartArr.length) {
        this.getPdf();
      } else {
        this.$message({
          type: "error",
          message: "暂无数据可供下载！",
          duration: 1000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/recognition.scss";
.chart-title {
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 14px;
  border-bottom: 1px solid #cdcdcd;
  color: #999;
}
.charts-wrap {
  height: 280px;
}
</style>
