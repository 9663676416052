<template>
  <el-date-picker v-model="dateRange" @change="handleChange" :unlink-panels="true" size="small" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="选择开始日期" end-placeholder="选择结束日期" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data() {
    return {}
  },
  computed: {
    dateRange: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {},
  methods: {
    handleChange(val){
      if(val){
        this.dateRange = val
      }else{
        this.dateRange = ['','']
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>